import * as React from 'react';

export const Logo = React.forwardRef<
    SVGSVGElement,
    React.ComponentPropsWithoutRef<'svg'>
>((props, ref) => (
    <svg
        viewBox="0 0 3593 930"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M73.3731 930L434.656 0H362.878L0 930H73.3731Z"
            fill="currentColor"
        />
        <path
            d="M795.939 930L434.656 0H506.434L869.312 930H795.939Z"
            fill="currentColor"
        />
        <path
            d="M434.655 0L478.69 51.6667H390.621L434.655 0Z"
            fill="currentColor"
        />
        <path
            d="M2016.34 930L2377.62 0H2305.85L1942.97 930H2016.34Z"
            fill="currentColor"
        />
        <path
            d="M2738.91 930L2377.63 0H2449.4L2812.28 930H2738.91Z"
            fill="currentColor"
        />
        <path
            d="M2377.62 0L2421.66 51.6667H2333.59L2377.62 0Z"
            fill="currentColor"
        />
        <path
            d="M1062.69 930L1822.97 0H1751.19L989.312 930H1062.69Z"
            fill="currentColor"
        />
        <path
            d="M1749.6 930L989.312 0H1061.09L1822.97 930H1749.6Z"
            fill="currentColor"
        />
        <rect x="2932.28" width="72.1693" height="930" fill="currentColor" />
        <rect
            x="2968.75"
            y="930"
            width="72"
            height="624"
            transform="rotate(-90 2968.75 930)"
            fill="currentColor"
        />
    </svg>
));

Logo.displayName = 'Logo';
