import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useEffect, useRef } from "react";
import p5 from "p5";
import { Logo } from "./AxalIcon.tsx"; // Make sure to import Icon from the correct path

function ExternalRedirect({ to }) {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null; // Render nothing
}

function sketch(p) {
  let dots = [];
  let center;

  p.setup = function () {

    const size = 800;
    p.createCanvas(size, size);

    center = p.createVector(p.width / 2, p.height / 2);
    initDots();
  };
  
  function initDots() {
    dots = []; // Clear existing dots
    let cols = 32;
    let rows = 32;
    let x_gap = (p.width / 2) / cols;
    let y_gap = (p.height / 2) / rows;
    let offsetX = (p.width - 400) / 2;
    let offsetY = (p.height - 400) / 2;

    for (let i = 0; i < cols; i++) {
      for (let j = 0; j < rows; j++) {
        let x = i * x_gap + x_gap / 2 + offsetX;
        let y = j * y_gap + y_gap / 2 + offsetY;
        dots.push(new Dot(x, y));
      }
    }
  }

  p.draw = () => {
    p.background(0);
    dots.forEach(dot => {
      dot.move();
    });
  };

  class Dot {
    constructor(x, y) {
      this.p = p.createVector(x, y);
      this.v = p.createVector();
      this.a = p.createVector(0, 0.1);
    }

    move() {
      this.a = p5.Vector.sub(center, this.p);
      this.a.setMag(0.05);
      this.v.add(this.a);
      this.p.add(this.v);
      p.fill(255);
      p.noStroke();
      p.circle(this.p.x, this.p.y, 5);
    }
  }
}

function App() {
  const p5ContainerRef = useRef();

  useEffect(() => {
    const p5Instance = new p5(sketch, p5ContainerRef.current);
    return () => p5Instance.remove();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <div className="App">
            <Logo className="app-icon-axal" width="300" />
            <div className="canvas-container" ref={p5ContainerRef} />
            <div className="text-container">
              <a className="jobs" href="/jobs">Jobs</a>
              <a className="email" href="mailto:hello@getaxal.com">hello@getaxal.com</a>
              <a className="tagline">Verifiable Autonomous Agents</a>
              <p className="copyright">
                {`© ${new Date().getFullYear()} Lockbox Technologies, Inc. All rights reserved.`}
              </p>
            </div>
          </div>
        } />
        <Route path="/jobs" element={<ExternalRedirect to="https://axal.notion.site/Axal-Job-Board-e413c420e9cb421b8754504ea04a5c90" />} />
      </Routes>
    </Router>
  );
}

export default App;